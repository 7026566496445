import axios, { AxiosResponse } from 'axios';
import { HttpRequestParams } from './interfaces/http-request-params.interface';

/**
 * Class responsible for making aws api gateway requests to the service backend
 */
export class ApiGatewayClient {
    /**
     * Sends http requests to the external API
     * @param requestData Required params to send an Axios http call
     * @returns
     */
    public sendRequest<T>(
        requestData: HttpRequestParams,
    ): Promise<AxiosResponse<T>> {
        return axios.request(requestData);
    }

    /**
     * Sends post requests to the external API
     * @param url Request url
     * @param body Request body
     * @returns
     */
    public postRequest<T>(
        url: string,
        body: object | string,
    ): Promise<AxiosResponse<T>> {
        return axios.post(url, body, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        });
    }
}
